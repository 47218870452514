/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

//form validation
function validateFormHu() {

  var valid = true;
  var email = document.getElementById('bookEmail');
  var email_validation = document.getElementById("email_validation");
  var phone = document.getElementById('bookPhone');
  var phone_validation = document.getElementById('phone_validation');
  var name = document.getElementById('bookName');
  var name_validation = document.getElementById("name_validation");
  var filteremail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var filterphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  var filtername = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;

  if (name.value === "") {
    valid = false;
    name_validation.innerHTML = "Kérem adja meg a nevét !";
    name_validation.style.display = "block";
  } else if (!filtername.test(name.value)) {
    valid = false;
    name_validation.innerHTML = "Érvénytelen felhasználó név !";
    name_validation.style.display = "block";
  } else {
    name_validation.style.display = "none";
    name_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (email.value === "") {
    valid = false;
    email_validation.innerHTML = "Kérem adja meg az e-mail címét !";
    email_validation.style.display = "block";
  } else if (!filteremail.test(email.value)) {
    valid = false;
    email_validation.innerHTML = "Érvénytelen e-mail cím !";
    email_validation.style.display = "block";
  } else {
    email_validation.style.display = "none";
    email_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (phone.value === "") {
    valid = false;
    phone_validation.innerHTML = "Kérem adja meg a telefonszámát !";
    phone_validation.style.display = "block";
  } else if (!filterphone.test(phone.value)) {
    valid = false;
    phone_validation.innerHTML = "Érvénytelen telefonszám !";
    phone_validation.style.display = "block";
  } else {
    phone_validation.style.display = "none";
    phone_validation.parentNode.style.backgroundColor = "transparent";
  }
  return valid;
}

function validateFormEn() {

  var valid = true;
  var email = document.getElementById('bookEmail');
  var email_validation = document.getElementById("email_validation");
  var phone = document.getElementById('bookPhone');
  var phone_validation = document.getElementById('phone_validation');
  var name = document.getElementById('bookName');
  var name_validation = document.getElementById("name_validation");
  var filteremail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var filterphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  var filtername = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;

  if (name.value === "") {
    valid = false;
    name_validation.innerHTML = "Please enter your name !";
    name_validation.style.display = "block";
  } else if (!filtername.test(name.value)) {
    valid = false;
    name_validation.innerHTML = "Invalid username !";
    name_validation.style.display = "block";
  } else {
    name_validation.style.display = "none";
    name_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (email.value === "") {
    valid = false;
    email_validation.innerHTML = "Please enter your e-mail address !";
    email_validation.style.display = "block";
  } else if (!filteremail.test(email.value)) {
    valid = false;
    email_validation.innerHTML = "Invalid email address !";
    email_validation.style.display = "block";
  } else {
    email_validation.style.display = "none";
    email_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (phone.value === "") {
    valid = false;
    phone_validation.innerHTML = "Please enter your phone number!";
    phone_validation.style.display = "block";
  } else if (!filterphone.test(phone.value)) {
    valid = false;
    phone_validation.innerHTML = "Invalid phone number !";
    phone_validation.style.display = "block";
  } else {
    phone_validation.style.display = "none";
    phone_validation.parentNode.style.backgroundColor = "transparent";
  }
  return valid;
}

function validateFormRo() {

  var valid = true;
  var email = document.getElementById('bookEmail');
  var email_validation = document.getElementById("email_validation");
  var phone = document.getElementById('bookPhone');
  var phone_validation = document.getElementById('phone_validation');
  var name = document.getElementById('bookName');
  var name_validation = document.getElementById("name_validation");
  var filteremail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var filterphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  var filtername = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;

  if (name.value === "") {
    valid = false;
    name_validation.innerHTML = "Vă rugăm introduceți numele Dvs. !";
    name_validation.style.display = "block";
  } else if (!filtername.test(name.value)) {
    valid = false;
    name_validation.innerHTML = "Numele Dvs. este invalidă !";
    name_validation.style.display = "block";
  } else {
    name_validation.style.display = "none";
    name_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (email.value === "") {
    valid = false;
    email_validation.innerHTML = "Vă rugăm introducețt adresa Dvs. de e-mail !";
    email_validation.style.display = "block";
  } else if (!filteremail.test(email.value)) {
    valid = false;
    email_validation.innerHTML = "Adresă de e-mail invalidă !";
    email_validation.style.display = "block";
  } else {
    email_validation.style.display = "none";
    email_validation.parentNode.style.backgroundColor = "transparent";
  }
  if (phone.value === "") {
    valid = false;
    phone_validation.innerHTML = "Vă rugăm introduceți numărul Dvs. de telefon!";
    phone_validation.style.display = "block";
  } else if (!filterphone.test(phone.value)) {
    valid = false;
    phone_validation.innerHTML = "Număr de telefon invalid !";
    phone_validation.style.display = "block";
  } else {
    phone_validation.style.display = "none";
    phone_validation.parentNode.style.backgroundColor = "transparent";
  }
  return valid;
}


//scroll
function callScroll() {

  // use the first element that is "scrollable"
  function scrollableElement(els) {
    for (var i = 0, argLength = arguments.length; i < argLength; i++) {
      var el = arguments[i],
        jQueryscrollElement = jQuery(el);
      if (jQueryscrollElement.scrollTop() > 0) {
        return el;
      } else {
        jQueryscrollElement.scrollTop(1);
        var isScrollable = jQueryscrollElement.scrollTop() > 0;
        jQueryscrollElement.scrollTop(0);
        if (isScrollable) {
          return el;
        }
      }
    }
    return [];
  }

  // Scroll To
  function scrollTo(idElem, time) {
    jQuery("a[href=#" + idElem + "]").click(function (event) {
      // Prevent jump-down
      event.preventDefault();

      //removes the pixels from the top of the page
      var offTop = jQuery("#" + idElem).offset().top;
      offTop = offTop - 0;

      // Animate to target
      jQuery(scrollableElement('html', 'body')).animate({ scrollTop: offTop + 'px' }, 1000, function () {

        // Set hash in URL after animation successful
        //location.hash = idElem; // Removed this because it positions the element without the offTop
      });
    });
  }

  // Scroll Top
  jQuery('body').scrollTop(1);

  // scroll @ homepage
  scrollTo('why-3');
  scrollTo('why-2');
  scrollTo('pricing');
  scrollTo('contacts');
  scrollTo('book');

}


(function ($) {

  //simple text rotate
  var defaults = {
    animation: "fade",
    separator: ",",
    speed: 2000
  };

  $.fx.step.textShadowBlur = function (fx) {
    $(fx.elem).prop('textShadowBlur', fx.now).css({ textShadow: '0 0 ' + Math.floor(fx.now) + 'px black' });
  };

  $.fn.textrotator = function (options) {
    var settings = $.extend({}, defaults, options);

    return this.each(function () {
      var el = $(this);
      var array = [];
      $.each(el.text().split(settings.separator), function (key, value) {
        array.push(value);
      });
      var current = Math.floor(Math.random() * array.length);
      el.text(array[current]);

      // animation option
      var rotate = function () {
        switch (settings.animation) {
          case 'fade':
            el.fadeOut(settings.speed, function () {
              index = Math.floor(Math.random() * array.length);
              el.text(array[index]).fadeIn(settings.speed);
            });
            break;
        }
      };
      setInterval(rotate, settings.speed);
    });
  };

  // function validateForm() {

  //   var valid = true;
  //   var email = document.getElementById('bookEmail');
  //   var email_validation = document.getElementById("email_validation");
  //   var name = document.getElementById('bookName');
  //   var name_validation = document.getElementById("name_validation");
  //   var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //   if (name.value === "") {
  //     valid = false;
  //     name_validation.innerHTML = "Preenche com o teu nome por favor.";
  //     name_validation.style.display = "block";
  //   } else {
  //     name_validation.style.display = "none";
  //     name_validation.parentNode.style.backgroundColor = "transparent";
  //   }
  //   if (!filter.test(email.value)) {
  //     valid = false;
  //     email_validation.innerHTML = "Endereço de email inválido";
  //     email_validation.style.display = "block";
  //   } else {
  //     email_validation.style.display = "none";
  //     email_validation.parentNode.style.backgroundColor = "transparent";
  //   }
  //   if (email.value === "") {
  //     valid = false;
  //     email_validation.innerHTML = "Preenche com o teu email por favor.";
  //     email_validation.style.display = "block";
  //   } else {
  //     email_validation.style.display = "none";
  //     email_validation.parentNode.style.backgroundColor = "transparent";
  //   }
  //   return valid;
  // }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        var target;
        // JavaScript to be fired on all pages

        //pricing boxes equal heights
        $('.pricing-box').matchHeight();
        $('.3column').matchHeight();

        //contact message info
        if (window.location.href.indexOf('success') > -1) {

          $('.alert-success').css('display', 'block');
          target = $('#book');

          if (target.length) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        } else if (window.location.href.indexOf('failed') > -1) {

          $('.alert-danger').css('display', 'block');
          target = $('#book');

          if (target.length) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        }

        //back top of page button
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollToTop').fadeIn();
          } else {
            $('.scrollToTop').fadeOut();
          }
        });

        $('.scrollToTop').click(function () {
          $('html, body').animate({ scrollTop: 0 }, 500);
          return false;
        });




        //$(window).scrollTop($('#book'));

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //navigation smooth scroll
        callScroll();

        //simple text rotate
        $(".rotate-text .rotate").textrotator({
          animation: "fade",
          speed: 2500
        });

        //scroll text animantions
        window.sr = ScrollReveal({ reset: true });
        sr.reveal('.animate', { duration: 500 });


      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
